<template>
  <div>
    <section
      class="g-bg-pos-center g-bg-size-cover"
      style="background-image: url(assets/img/fondo-ingreso-wide.jpg);height:300px;"
    >
      <div class="container g-color-white text-center g-py-210"></div>
    </section>

    <section class="container-fluid g-px-100--lg g-px-50--sm g-py-80">
      <h1 class="g-font-weight-500 g-mb-15 font-color-title">
        Ingresar una Consulta
      </h1>
      <hr class="g-my-40" style="border: 1px solid; color: #555;!important" />
      <div class="row no-gutters g-mx-minus-10">
        <div class="col-sm-6 col-lg-6 g-px-10 text-center">
          <router-link
            to="#"
            v-on:click.native="enter(allConstants.security.userTypes.person)"
            class="btn btn-xl u-btn-blue-b u-btn-content g-mr-2 g-mb-15"
            ><img src="assets/img/natural-person.svg" /> Persona
            Natural</router-link
          >
        </div>
        <div class="col-sm-6 col-lg-6 g-px-10 text-center">
          <router-link
            to="#"
            v-on:click.native="enter(allConstants.security.userTypes.business)"
            class="btn btn-xl u-btn-cyan u-btn-content g-mr-2 g-mb-15"
            ><img src="assets/img/legal-person.svg " /> Persona
            Jurídica</router-link
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import currentQueryInCreationMixin from "@/mixins/currentQueryInCreation";

export default {
  mixins: [baseViewMixin, currentQueryInCreationMixin],
  methods: {
    enter(userType) {
      let currentQueryInCreation = this.currentQueryInCreation;
      currentQueryInCreation.userType = userType;

      this.currentQueryInCreationCreateOrUpdate(currentQueryInCreation).then(
        () => {
          this.gotoView("queryCreationFlow");
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
a.btn {
  color: white !important;
}
.font-color-title {
  color: #555;
  font-size: 2.5rem;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}
</style>
